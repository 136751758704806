.ThresholdIcon {
    border-radius: 4px;
    padding: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(128, 149, 196, 0.5);
    margin-bottom: 5px;
    color: #444;
    text-decoration: underline;
}