.FilterAddForm {
    width: calc(100% - 40px);
}
.FilterAddForm > DIV {
    display: grid;
    grid-template-columns: auto 215px;
    width: inherit;
    align-items: center;
    width: 100%;

}

.FilterAddForm > DIV.FullGrid {
    display: flex !important;
    grid-template-columns: none;
    width: 100%;
}