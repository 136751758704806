.Relative .Centered {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.Relative .Abs {
    position: absolute;
    width: 100%;
    height: 100%;
}