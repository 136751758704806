.RightTabScroller {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 1 1;
}
.DetailActionButtons {
    padding: 10px;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}