.MainMenu {
    display: flex;
    flex-direction: column;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 5px;
    justify-content: space-between;
    overflow-y: auto;
}

.FullMainMenu {
    min-width: 200px;
    width: 220px;
    border-radius: 15px;
}

.CollapsedMainMenu {
    width: 63px;
    border-radius: 15px;
}

.MainMenu .Branding {
    height: 80px;
    padding: 5px;
}

.CollapsedMainMenu > .BottomIcons,
.CollapsedMainMenu > .TopIcons {
    display: flex;
    flex-direction: column;
    font-size: 155%;
    align-items: center;
}

.CollapsedMainMenu > .TopIcons > DIV {
    cursor: pointer;
    margin: 6px;
}

.CollapsedMainMenu > .BottomIcons > DIV {
    cursor: pointer;
}

.MainMenu .Branding {
    cursor: pointer;
}

.MainMenu .Branding > .T {
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 175%;
    background-color: whitesmoke;
    color: #000;
    font-weight: 600;
    justify-content: center;
    border-radius: 6px;
    font-family: Viga, "IBM Plex Sans", serif;
}
.MainMenu .Branding > .Tracardi {
    margin-top: 10px;
    margin-left: 20px;
    font-size: 120%;
    text-transform: uppercase;
    font-weight: 400;
    font-family: Viga, "IBM Plex Sans", serif;
}

.MainMenu .Branding > .Version {
    margin-left: 20px;
    font-size: 80%;
    font-weight: 300;
}

.MainMenu .Context {
    padding: 3px 10px;
    background-color: white;
    border-radius: 10px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
}
.MainMenu .MenuCollapsedRow,
.MainMenu .MenuRow {
    display: flex;
    flex-wrap: nowrap;
    color: rgba(225,225,225, 0.95);
    align-items: center;
    flex-direction: row;
    padding: 6px 7px;
    margin: 3px 3px 3px 6px;
    cursor: pointer;
    font-size: 150%;
    height: 30px;
    border-radius: 5px;
}

@keyframes fadeInMenu {
    0% {
        opacity: .8;
    }
    100% {
        opacity: 1;
    }
}
.MainMenu .MenuCollapsedRow.active,
.MainMenu .MenuCollapsedRow:hover,
.MainMenu .MenuRow.active,
.MainMenu .MenuRow:hover {
    color: white;
    background-color: #ef6c00;
    z-index: 20
}
.MainMenu .MenuCollapsedRow > SPAN.Icon,
.MainMenu .MenuRow > SPAN.Icon {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.MainMenu .MenuCollapsedRow > SPAN.Label,
.MainMenu .MenuRow > SPAN.Label {
    display: flex;
    margin-left: 10px;
    align-items: center;
    font-size: 14px;
}

.MainMenu .MenuCollapsedRow > SPAN.Label {
    display: none;
    border-radius: 4px;
    padding: 6px 15px;
}
.MainMenu .MenuCollapsedRow:hover > SPAN.Label {
    display: flex;
    position: absolute;
    left: 65px;
    background-color: rgba(128, 128, 128, .4);
    backdrop-filter: blur(20px);
    margin-left: 10px;
    align-items: center;
    font-size: 14px;
    z-index: 20;
}

