.CardBrowser {
    height: inherit;
    align-items: flex-start;
    display: grid;
    grid-template-rows: 55px calc(100% - 55px);
    width: inherit;
}

.CardBrowser > H3 {
    display: flex;
    align-content: center;
    justify-content: center;
    text-transform: uppercase;
    margin: 0;
    font-weight: 600;
    width: 100%;
    letter-spacing: 1px;
}

.CardBrowser > SECTION {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: inherit;
}

.CardBrowser .RowGroup,
.CardBrowser .CardGroup {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: fit-content;
}

.CardBrowser .RowGroup > HEADER {
    padding: 5px 10px 5px 10px;
    font-size: 120%;
    border-bottom: 2px #444 solid;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.CardBrowser .CardGroup > HEADER {
    padding: 5px 10px 5px 10px;
    font-size: 120%;
    border-bottom: 1px #ccc solid;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.CardBrowser .CardGroup > DIV {
    overflow: auto;
    height: inherit;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.CardBrowser .RowGroup > DIV > DIV:hover {
    background-color: rgba(200, 200, 200, 0.2);
}

.CardBrowser .RowGroup > DIV {
    overflow: auto;
    height: inherit;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}