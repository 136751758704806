@import url('https://fonts.googleapis.com/css2?family=Viga&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400;1,500&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
  overflow: hidden;
}

html {
  height:100%;
  font-size: 14px;
}

html * {
  scrollbar-width: thin;
  scrollbar-color: #666 transparent;
}
html *::-webkit-scrollbar {
  width: 4px;
}
html *::-webkit-scrollbar-track {
  background-color: rgb(235, 235, 235);
  border-radius: 3px;
}
html *::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #888;
}
html *::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 109, 179, 0.2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height:100%;
}

H1.header {
  font-size: 185%;
  margin: 0 0 15px 0;
  font-weight: 400;
  opacity: 80%;
  text-transform: none;
}

H2.subHeader {
  font-size: 130%;
  margin: 0 0 15px 0;
  font-weight: 400;
  opacity: 70%;
  text-transform: none;
}

.flexLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.boxRowUnderlines > DIV {
  border-bottom: solid rgba(128,128,128,.3) 1px;
}

.boxRowUnderlines > DIV:hover {
  background-color: rgba(200,200,200,0.2);
}

.boxRowUnderlines >DIV:last-child {
  border-bottom: 0;
}

.highlightLine:hover {
  background-color: rgba(200,200,200,0.2);
}

.tipBox {
  background-color: #E1F5FE;
  border-radius: 5px;
  border: solid #81D4FA 1px
}

.tipBox .tipBody {
  padding: 20px;
  color: #222;
}

.tipBox .tipHeader {
  background-color: #81D4FA;
  padding: 10px;
}

.keyButton {
  font-size:11px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: black;
  color: white;
  margin: 0 5px;
}