.JsonStringify {

}

.JsonStringify SPAN.JsonItem {
    margin-right: 7px;
    line-height: 28px;
    padding: 0px 10px 0px 1px;
}
.JsonStringify SPAN.JsonItem:hover {
    background: rgba(255,255,255,0.9);
    border-radius: 4px;
    color: rgba(0,0,0,.9)
}

.JsonStringify SPAN.JsonItem:hover SPAN.JsonValue {
    text-decoration: underline;
}

.JsonStringify SPAN.JsonItem:hover SPAN.JsonKey {
    background-color: rgba(0,0,0, 0.5);
    color: rgba(255,255,255, 0.9);
}

.JsonStringify SPAN.JsonItem > SPAN.JsonValue {
    padding: 1px 1px;
    border-radius: 3px;
    word-wrap: anywhere;
}

.JsonStringify SPAN.JsonItem > SPAN.JsonKey {
    background-color: rgba(200,200,200, 0.5);
    padding: 2px 4px;
    border-radius: 4px;
    margin:0 2px 0 1px;
}