.MainContent {
    display: flex;
    flex-direction: row;
    width:100%;
    height: 100%;
    gap: 10px;
    padding: 10px;
}

.MainContent > .MainPane {
    width: inherit;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 15px;
}