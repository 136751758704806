.ErrorBox {
    background: #d81b60;
    color: white;
    padding: 15px;
    overflow-x: auto;
    margin: 10px;
    display: flex;
    flex-direction: row;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.4s;
    align-items: center;
}

.ErrorBox > .ErrorDetails {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font-size: 120%;
    margin-top: 0;
}
